import * as React from "react";
import { FC } from "react";
import { CvEntryPoint } from "../../../entrypoints/cv";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.plPL} entrypoint={"cv"} page={"zyciorys"}>
    <CvEntryPoint lang={LANG.plPL} />
  </DefaultLayout>
);
